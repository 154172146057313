// Variables
$max-body-width: 1190px;
$max-mobile-width: 1100px;

// Global Styles
html, body {
    background-color: #101F31;
}

body {
    max-width: $max-body-width;
    margin: 40px;
    margin-bottom: 0;
    font-family: 'Rethink Sans', sans-serif;

    @media (min-width: $max-body-width) {
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: $max-mobile-width) {
        margin: 0;
    }
}

// Header Styles
header {
    background-image: url('/images/hero.jpg');
    background-size: cover;
    background-position: center;
    height: 768px;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    nav {
        display: flex;
        justify-content: space-between;
        max-width: 80%;
        align-items: center;
        padding: 20px 0;
        margin: auto;

        a {
            color: #FFFFFF;
            text-transform: uppercase;
            text-decoration: none;
        }
    }

    .tagline {
        font-size: 60px;
        color: #FEEBE0;
        text-transform: uppercase;
        text-align: center;
        font-family: 'Paytone One', sans-serif;
        line-height: 1; 
        max-width: 800px;
        margin: auto;

        @media (max-width: $max-mobile-width) {
            font-size: 40px;
            margin: 80px;
        }
    }

    .continue-button {
        display: flex;
        justify-content: center;
        margin-top: 100px;

        @media (max-width: $max-mobile-width) {
            padding: 20px;
            padding-top: 0;
        }
    }
}

// Main Styles
main {
    margin-top: -10px;
    padding: 40px;
    padding-top: 0;
    color: #432d19;
    font-size: 17px;
    background-color: #d3c5bc;

    @media (max-width: $max-mobile-width) {
        padding: 20px;
        padding-top: 0;
    }

    .tagline {
        margin: 0;
        margin-top: 80px;
        margin-bottom: -10px;
        color: #B74102;
        font-family: 'Paytone One', sans-serif;
        font-size: 18px;
        text-transform: uppercase;

        &:first-of-type {
            margin-top: 0;
        }
    }

    h1 {
        font-family: 'Paytone One', sans-serif;
        font-size: 40px;
        text-transform: uppercase;
        margin: 0px;
        color: #000000;

        a {
            color: #572105;
            text-decoration: none;

            &:hover {
                color: #B74102;
            }
        }
    }

    h2 {
        font-size: 20px;
        text-transform: uppercase;
        margin: 0px;
        color: #B74102;
        font-weight: normal;
    }

    > p {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #386584;

    h2 {
        color: #ceebff;
        font-family: 'Paytone One', sans-serif;
    }

    a {
        color: #ceebff;
        text-decoration-style: wavy;
        text-decoration-color: #4f7d9b;

        &:hover {
            color: #FFFFFF;
            text-decoration-color: #FFFFFF;
        }
    }

    hr {
        margin: 40px 0;
        border: 0;
        border-bottom: 1px solid #305670;
    }


    .base-info {
        padding: 40px;
        color: #FFFFFF;
    }

    .base-hours {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .base-hours-time {
            color: #c5dceb;
            text-align: right;
        }

        > div {
            padding: 5px;
            border-bottom: 1px solid #305670;
        }

        > :last-child, :nth-last-child(2) {
            border: 0;
        }
    }

    .base-social {
        margin-top: 20px;
        display: flex;
        gap: 15px;

        img {
            width: 30px;
        }
    }

    #map {
        height: 100%;
        width: 100%;
    }
}